import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link, } from "gatsby"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        {
            allWinnersJson {
                edges {
                    node {
                        awards
                        winnerBadgeUrl
                        winnerCertUrl
                        runnerBadgeUrl 
                        runnerCertUrl 
                        awardTrophyUrl                  }
                }
            }
        }
    `)

    return (
        <Layout pageTitle="Award Winners">
            <Row>
                <Col>
                    <Link to="/awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <p>Click download link to find your badge. You will need to 'use template' to view and download the correct badge. Please don't alter or copy. Download your own badge.</p>
                </Col>
            </Row>
            {data.allWinnersJson.edges.map(({ node }) => {
                return (
                    <Row className="mt-5">
                        <Col>
                            <h2>{node.awards}</h2>
                            <Row>
                                <Col md={12}>
                                    {node.winnerBadgeUrl && (
                                        <a
                                            href={node.winnerBadgeUrl}
                                            className="btn btn-primary m-3"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Winner Badge
                                        </a>
                                    )}
                                    {node.winnerCertUrl && (
                                         <a
                                             href={node.winnerCertUrl}
                                             className="btn btn-primary m-3"
                                             rel="noopener noreferrer"
                                             target="_blank"
                                          >
                                              Winner Certificate
                                         </a>
                                    )}
                                     {node.runnerBadgeUrl && (
                                         <a
                                            href={node.runnerBadgeUrl}
                                             className="btn btn-primary m-3"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Runner Up Badge
                                        </a>
                                    )}
                                    {node.runnerCertUrl && (
                                        <a
                                              href={node.runnerCertUrl}
                                             className="btn btn-primary m-3"
                                             rel="noopener noreferrer"
                                             target="_blank"
                                          >
                                              Runner Up Certificate
                                         </a>
                                    )}
                                    {node.awardTrophyUrl && (
                                        <a
                                              href={node.awardTrophyUrl}
                                             className="btn btn-primary m-3"
                                             rel="noopener noreferrer"
                                             target="_blank"
                                          >
                                              Award Trophy
                                         </a>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })}
        </Layout>
    )
}

export default IndexPage
